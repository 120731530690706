import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import * as styles from './researchCard.module.css';

const ResearchCard = (props) => {
	return (
		<article className={styles.card}>
			<section className={styles.articleInfo}>
				<h4>{props.title}</h4>
				<div className={styles.authorsList}>
					{props.authors.map((author) => (
						<span className={styles.authorName}>{author}</span>
					))}
				</div>
				<div className={styles.lowerContainer}>
					<h6>{props.journalName}</h6>
					<a href={props.journalLink} target='blank'>
						See Journal Page
					</a>
					<h6>{props.journalIssue}</h6>
				</div>
			</section>
			{props.description ? (
				<div className={styles.pulloutTab}>
					<p className={styles.descriptionLabel}>Description</p>
					<FontAwesomeIcon
						icon={faChevronRight}
						className={styles.chevron}
					/>
				</div>
			) : (
				<></>
			)}
		</article>
	);
};

export default ResearchCard;
