import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/layout';
import ResearchCard from '../components/researchPage/researchCard';

import * as styles from './research.module.css';

const ResearchPage = () => {
	const {
		contentfulResearchPage: { researchStatement, papers, backgroundImage },
	} = useStaticQuery(graphql`
		{
			contentfulResearchPage {
				backgroundImage {
					file {
						url
					}
				}
				papers {
					title
					authors
					journalName
					journalIssue
					url
					description {
						childMarkdownRemark {
							html
						}
					}
				}
				researchStatement {
					childMarkdownRemark {
						html
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<section
				className={styles.splash}
				style={{ backgroundImage: `url(${backgroundImage.file.url})` }}
			>
				<article className={styles.content}>
					<h1>Research</h1>
					<div
						dangerouslySetInnerHTML={{
							__html: researchStatement.childMarkdownRemark.html,
						}}
					></div>
				</article>
				<ResearchCard
					title={papers[0].title}
					authors={papers[0].authors}
					journalName={papers[0].journalName}
					journalIssue={papers[0].journalIssue}
					journalLink={papers[0].url}
					description=''
				/>
			</section>
			{papers.length > 1 ? (
				<section className={styles.moreResearch}>
					{papers.slice(1).map((paper, idx) => {
						return (
							<ResearchCard
								title={paper.title}
								authors={paper.authors}
								journalName={paper.journalName}
								journalIssue={paper.journalIssue}
								journalLink={paper.url}
								description=''
							/>
						);
					})}
				</section>
			) : (
				<></>
			)}
		</Layout>
	);
};

export default ResearchPage;
